<template>
  <div class="layout-header">
    <div class="d-flex">
      <div class="col-fixed" style="width:100px">
        <img src="@/assets/logo-el.png" style="width: 80px" />
      </div>
      <div class="col">
        <h2>Portal de Serviços</h2>
        <div v-if="this.currentUserAdmin && this.currentUserAdmin.usuario && this.currentUserAdmin.usuario.pessoa">Administrador:
          {{ this.currentUserAdmin.usuario.pessoa.numeroDocumentoJuridico }} - {{ this.currentUserAdmin.usuario.pessoa.nomeGeral }} 
        </div>

        <div v-if="this.currentUser && this.currentUser.usuario && this.currentUser.usuario.pessoa">Usuário:
          {{ this.currentUser.usuario.pessoa.numeroDocumentoJuridico }} - {{ this.currentUser.usuario.pessoa.nomeGeral }}
        </div>
        <div style="padding-top:2em !important">
          <i class="pi pi-angle-double-right" v-if="menuSelected !== null" />
          {{ menuSelected }}
        </div>
      </div>
    </div>

    <nav class="navbar navbar-expand-md navbar-dark bg-dark">
      <div class="container-fluid">

        <!-- <div class="col-12 d-flex justify-content-end p-0">
          <div class="pr-3">
            <Button
              class="p-button-text p-button-plain"
              icon="pi pi-bars"
              @click="toggle"
            />
            <Menu ref="tieredMenu" :model="tieredMenu" :popup="true" />
          </div>
        </div> -->

        <a class="navbar-brand" href="#" />
        <button class="navbar-toggler mr-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
          <span class="navbar-toggler-icon" />
        </button>

        <div class="collapse navbar-collapse" id="navbarCollapse" v-if="funcionalidades">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item dropdown"
              v-for="func in funcionalidades.filter(property => property.pagina === '/homeCredito')" :key="func.id">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
                aria-expanded="false">
                {{ func.nomeFuncionalidade }}
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li v-if="this.isUserAdmin"><router-link class="dropdown-item" to="/usuarioCredito"
                  @click="$store.dispatch('SET_MENU_SELECTED', 'Seleção Usuário')">
                  Seleção Usuário
                </router-link></li>
                <li v-if="this.isUserSelecionado"><router-link class="dropdown-item" to="/homeCredito"
                  @click="$store.dispatch('SET_MENU_SELECTED', 'Home Crédito')">
                  Home
                </router-link></li>
                <li v-if="this.isUserSelecionado"><router-link class="dropdown-item" to="/extrato-credito"
                  @click="$store.dispatch('SET_MENU_SELECTED', 'Extrato Crédito')">
                  Extrato
                </router-link></li>
                <li v-if="this.isUserSelecionado"><router-link class="dropdown-item" to="/regrasCredito"
                  @click="$store.dispatch('SET_MENU_SELECTED', 'Regras de Utilização')">
                  Regras Utilização
                </router-link></li>
                <li v-if="this.isUserSelecionado"><router-link class="dropdown-item" to="/credito"
                  @click="$store.dispatch('SET_MENU_SELECTED', 'Indicar Imóveis')">
                  Indicar Imóveis
                </router-link></li>
              </ul>
            </li>
            <li class="nav-item active"
              v-for="func in funcionalidades.filter(property => property.pagina !== '/homeCredito')" :key="func.id">
              <router-link :key="func.id" :to="func.pagina || '/'" class="nav-link"
                @click="$store.dispatch('SET_MENU_SELECTED', func.nomeFuncionalidade)">
                {{ func.nomeFuncionalidade }}
              </router-link>
            </li>

          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import loginService from '../services/login.service';

export default {
  data() {
    return {
      currentUser: null,
      currentUserAdmin: null,
      tieredMenu: []
    };
  },

  props: {
    funcionalidades: {
      type: Array,
      default: () => [],
    },
  },

  created() {
    this.atualizarDados();
  },
 

  computed: {
    isUserAdmin(){
      return this.currentUserAdmin && this.currentUserAdmin.acessoMaster === 'S';
    },

    isUserSelecionado(){
      return this.currentUser;
    },

    menuSelected() {
      return this.$store.getters.getMenuSelected;
    }
  },

  methods: {
    atualizarDados() {
      this.currentUser = loginService.currentUserValue;
      this.currentUserAdmin = loginService.currentUserAdminValue;
    },
    navigate(path) {
      const toPath = (path !== undefined && path !== null) ? path : '/';
      if (this.$route.path !== toPath) {
        this.$router.push(toPath);
      }
    },

    setIconTieredMenu(pagina) {
      switch (pagina) {
        case '/': return 'pi pi-home';
        case 'extrato': return 'pi pi-calculator';
        case 'documento': return 'pi pi-folder';
        case 'itbi': return 'pi pi-file-edit';
        case 'dam': return 'pi pi-qrcode';
        default: return 'pi pi-file';
      }
    },

    setTieredMenu() {
      this.tieredMenu = this.funcionalidades.map(v => ({
        label: v.nomeFuncionalidade,
        icon: this.setIconTieredMenu(v.pagina),
        command: () => this.navigate(v.pagina),
      }));
    },

    toggle(event) {
      this.$refs.tieredMenu.toggle(event);
    },
  },
};
</script>
<style lang="scss">
.p-menu {
  width: 15rem !important;
}

@media (max-width: 768px) {
  .nav-item {
    padding-left: 1rem;
  }
}
</style>
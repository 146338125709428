<template>
  <Header :funcionalidades="funcionalidades" ref="header"/>

  <div class="layout-main">
    <div class="content container-fluid">
      <router-view />
    </div>
  </div>

  <Footer :version="version" />

  <Toast />
  <Spinner />
</template>

<script>
import { Footer, Header, Spinner } from "@/components";
import PortalService from "@/services/portal.service";
import LoginService from "@/services/login.service";
import pkg from '../package.json';
import _ from 'lodash';

export default {
  name: "App",

  components: {
    Footer,
    Header,
    Spinner,
  },

  data() {
    return {
      version: pkg.version,
      funcionalidades: [],
    };
  },

  async created() {
    await this.fetchFuncionalidades();
    await this.getTrbToken();
  },

  computed: {
    serverExec() {
      return this.$store.getters.getServerExec;
    },
    idCliente() {
      return this.$store.getters.getIdCliente;
    },
    menuFuncionalidades() {
      return this.funcionalidades;
    },
    appToken() {
      return this.$store.getters.getTrbToken;
    }
  },

  methods: {
    getFuncionalidadeHome() {
      return {
        id: 'home',
        nomeFuncionalidade: "Home",
        pagina: "/"
      };
    },

    atualizarDadosHeader() {
      this.$refs.header.atualizarDados();
    },

    async fetchFuncionalidades() {
      try {
        this.currentUser = LoginService.currentUserValue;
        this.currentUserAdmin = LoginService.currentUserAdminValue;

        let idUsuario = null;

        if (this.currentUserAdmin && this.currentUserAdmin.usuario && this.currentUserAdmin.usuario.id) {
          idUsuario = this.currentUserAdmin.usuario.id;
        } else if (this.currentUser && this.currentUser.usuario && this.currentUser.usuario.id) {
          idUsuario = this.currentUser.usuario.id;
        }
        
        this.$store.dispatch('SET_ENABLE_SPINNER', true);

        const result = await PortalService.findFuncionalidades(this.idCliente, idUsuario);
        
        const filterFunc = result.filter(v => v.pagina !== 'itbi');

        this.funcionalidades = [
          this.getFuncionalidadeHome(),
          /*{
            id: 'extrato-credito',
            nomeFuncionalidade: "Extrato de Crédito",
            pagina: "/extrato-credito"
          },*/
          ...(_.orderBy(filterFunc, 'ordemFuncionalidade', 'asc')),
        ];

      } catch (errorMessage) {
        console.log(errorMessage);
        this.$toast.add({
          severity: "error",
          summary: "Houve um problema ao listar as configurações!",
          life: 3000,
        });
      } finally {
        this.$store.dispatch('SET_ENABLE_SPINNER', false);
      }
    },

    async getTrbToken() {
      try {
        this.$store.dispatch('SET_ENABLE_SPINNER', true);
        
        const login = {
          usuario: this.$store.getters.getTrbUser,
          senha: this.$store.getters.getTrbPassword,
        }
        const result = await PortalService.getTrbToken(this.serverExec, login);

        await this.$store.dispatch('SET_TRB_TOKEN', result);

      } catch (errorMessage) {
        console.log(errorMessage);
        this.$toast.add({
          severity: "error",
          summary: "Houve um problema ao gerar token!",
          life: 3000,
        });
      } finally {
        this.$store.dispatch('SET_ENABLE_SPINNER', false);
      }
    },

  },
};
</script>
<style lang="scss">
@viewport {
  zoom: 5
}
  .layout-main {
    display: flex;
    flex: 1;
    flex-direction: row;
    
    .content {
      padding: 20px 20px 20px 20px !important;
    }
  }

  @media (max-width: 768px) {
    .layout-main {
      .content {
        padding: 10px !important;
      }
    }
  }
</style>